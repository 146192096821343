import { Cell, Stepper, StepperProps } from "react-vant";

interface NumberStepperProps extends Omit<StepperProps, "onChange"> {
  title?: string;
  value?: number;
  onChange?: (value: number) => void;
}

export default ({
  title = "数量",
  value,
  onChange,
  ...props
}: NumberStepperProps) => {
  return (
    <Cell title={title} center>
      <Stepper
        value={value}
        onChange={(e) => onChange && onChange(Number(e))}
        {...props}
      />
    </Cell>
  );
};
